"use strict";
jQuery.migrateMute = true;
import Mapbox from "./includes/mapbox";

document.addEventListener("DOMContentLoaded", () => {
  new Mapbox();
});

const buttonOne = document.querySelector(".button-one");

buttonOne.addEventListener("click", () => {
  const isOpened = buttonOne.getAttribute("aria-expanded");
  if (isOpened === "false") {
    buttonOne.setAttribute("aria-expanded", "true");
  } else {
    buttonOne.setAttribute("aria-expanded", "false");
  }
});
