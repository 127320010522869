import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default class Mapbox {
  constructor() {
    this.MapboxContainer = document.getElementById("Mapbox");

    if (this.MapboxContainer) {
      this.init();
    }
  }

  init() {
    const pin = this.MapboxContainer.getAttribute("data-pin");
    const point = JSON.parse(pin);
    console.log(point);
    const center = this.MapboxContainer.getAttribute("data-center");
    const zoom = this.MapboxContainer.getAttribute("data-zoom");

    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWx0ZXJpb2RldiIsImEiOiJjbDk2dDVmNGIwOHh3M3dwYXQ2Nm80aXplIn0.QRbI4Ayg_8kY3lWxWzELiQ";

    const map = new mapboxgl.Map({
      container: "Mapbox", // container ID
      style: "mapbox://styles/alteriodev/cl98el9w500cn14l31lnd1qyk", // style URL
      center: [JSON.parse(center)[0], JSON.parse(center)[1]], // starting position [lng, lat]
      zoom: zoom, // starting zoom
      projection: "globe", // display the map as a 3D globe
    });
    point.forEach((marker) => {
      const markerPopup = new mapboxgl.Popup().setHTML(
        '<a href="' + marker[3] + '" target="_blank">' + marker[2] + "</a>"
      );
      var el = document.createElement("div");
      el.className = "marker-image";
      el.style.backgroundImage = "url(" + marker[4] + ")";
      el.style.width = "28px";
      el.style.height = "28px";
      el.style.padding = "16px";
      el.style.backgroundColor = marker[5];
      el.style.backgroundSize = "100%";
      new mapboxgl.Marker(el)

        .setLngLat([marker[0], marker[1]])
        .setPopup(markerPopup)
        .addTo(map);
      map.on("style.load", () => {
        map.setFog({}); // Set the default atmosphere style
      });
    });
  }
}
